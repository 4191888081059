import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { IntroActions } from '../../actions';

import { Steps } from 'intro.js-react';

import 'intro.js/introjs.css';

import './Intro.scss';

function Intro() {

    const dispatch = useDispatch();

    const [stepsEnabled, setStepsEnabled] = useState(null);
	const introStatus = useSelector((state) => (!!state && !state.intro.intro_status)) || false;

    const steps = [
        {
            element: '.Entry',
            intro: 'Hello! &#128075; Welcome to my world.',
            position: 'left',
            tooltipClass: 'SideNavBar-Intro-Tooltip'
        },
        {
            element: '.SideNavBar-Close',
            intro: '&#128072; Side navigation menu here.',
            position: 'right',
            highlightClass: 'SideNavBar-Intro',
            tooltipClass: 'SideNavBar-Intro-Tooltip'
        },
        {
            element: '.technologies-icon',
            intro: 'Here are all the technologies I have experienced working with.',
            position: 'right',
            highlightClass: 'SideNavBar-Intro',
            tooltipClass: 'SideNavBar-Intro-Tooltip'
        },
        {
            element: '.face-recognition-icon',
            intro: '&#129302; Face recognition and accounts to stay connected to.',
            position: 'right',
            highlightClass: 'SideNavBar-Intro',
            tooltipClass: 'SideNavBar-Intro-Tooltip'
        },
        {
            element: '.timeline-icon',
            intro: 'My timeline, all the major activities and acheivements in my life.',
            position: 'right',
            highlightClass: 'SideNavBar-Intro',
            tooltipClass: 'SideNavBar-Intro-Tooltip'
        },
        {
            element: '.projects-icon',
            intro: 'A curated list of projects I have worked on.',
            position: 'top',
            highlightClass: 'SideNavBar-Intro',
            tooltipClass: 'SideNavBar-Intro-Tooltip'
        },
        {
            element: '.settings-icon',
            intro: 'Settings &#9881;  for webapp and things you can fiddle with, like intro.',
            position: 'top',
            highlightClass: 'SideNavBar-Intro',
            tooltipClass: 'SideNavBar-Intro-Tooltip'
        }
    ];

    function introStart() {
        setStepsEnabled(true);
        dispatch(IntroActions.introStart());
    }

    function onAfterChange(e) {
        dispatch(IntroActions.introChange(e));
    }

    function onIntroExit() {
        setStepsEnabled(false);
        dispatch(IntroActions.introStop());
    }

    useEffect(() => {
        introStart();
    }, []);

    return (
        <div className="Intro">
            <Steps
                enabled={stepsEnabled && introStatus}
                steps={steps}
                initialStep={0}
                onAfterChange={onAfterChange}
                onExit={onIntroExit}
                options={{ hideNext: false }}
            />
        </div>
    )
}

export default Intro;