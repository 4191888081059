import { IntroConstants } from "../constants";

const initialState = {};

const IntroReducer = (state = initialState, action) => {
    switch (action.type) {
        case IntroConstants.INTRO_START:
            return { ...state, intro_status: action.introStatus, step: 0 };
        case IntroConstants.INTRO_UPDATE:
            return { ...state, intro_status: state.intro_status, step: action.step };
        case IntroConstants.INTRO_STOP:
            return { ...state, intro_status: false, step: null }
        case IntroConstants.INTRO_SESSION_REMOVE:
            return { ...state, intro_status: true, step: 0 };
        default:
            return state;
    }
};

export default IntroReducer;