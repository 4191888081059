import { ProjectsConstants } from '../constants';
import { ProjectsService } from '../services';

const ProjectsActions = {
    getProjects: (offset, languageLogos) => {
        const request = () => ({ type: ProjectsConstants.GET_PROJECTS_REQUEST });
        const success = (Projects) => ({ type: ProjectsConstants.GET_PROJECTS_SUCCESS, Projects });
        const failure = () => ({ type: ProjectsConstants.GET_PROJECTS_FAILURE });

        return async (dispatch) => {
            dispatch(request());
            const response = await ProjectsService.getProjects(offset, languageLogos);
            if (response) {
                dispatch(success(response));
            } else {
                dispatch(failure());
            }
        };
    },
    getLanguageLogos: () => {
        const request = () => ({ type: ProjectsConstants.GET_LANGUAGE_LOGOS_REQUEST });
        const success = (languageLogos) => ({ type: ProjectsConstants.GET_LANGUAGE_LOGOS_SUCCESS, languageLogos });
        const failure = () => ({ type: ProjectsConstants.GET_LANGUAGE_LOGOS_FALIURE });

        return async (dispatch) => {
            dispatch(request());
            const response = await ProjectsService.getLanguageLogos();
            if (response) {
                dispatch(success(response));
            } else {
                dispatch(failure());
            }
        };
    }
};

export default ProjectsActions;