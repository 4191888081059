import { TechnologiesConstants } from '../constants';
import { TechnologiesService } from '../services';

const TechnologiesActions = {
    getTechnologies: (search, options=null) => {
        const request = () => ({ type: TechnologiesConstants.GET_TECHNOLOGIES_REQUEST });
        const success = (technologies) => ({ type: TechnologiesConstants.GET_TECHNOLOGIES_SUCCESS, technologies });
        const failure = () => ({ type: TechnologiesConstants.GET_TECHNOLOGIES_FAILURE });

        return async (dispatch) => {
            dispatch(request());
            const response = await TechnologiesService.getTechnologies(search, options);
            if (response) {
                dispatch(success(response));
            } else {
                dispatch(failure());
            }
        };
    }
};

export default TechnologiesActions;