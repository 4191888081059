import { initializeApp } from "firebase/app";
import {
  addDoc,
  collection,
  query,
  where,
  getDocs,
  getFirestore,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getPerformance } from "firebase/performance";
import firebaseConfig from "../helpers/firebaseConfig";

const BASE_URL = "http://localhost:5000/";
const FaceRecognitionService = {
  initialize: () => {
    const app = initializeApp(firebaseConfig);
    getPerformance(app);
    return app;
  },
  getUserFromFirebase: async (firebaseApp, email) => {
    let user = null;
    const db = getFirestore(firebaseApp);
    const q = query(collection(db, "Users"), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      user = doc.data();
      user.id = doc.id;
    });
    return user;
  },
  loginWithGoogle: async (firebaseApp) => {
    let user = null;
    const auth = getAuth(firebaseApp);
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);
    const _user = {
      name: result.user.displayName,
      email: result.user.email,
      phoneNumber: result.user.phoneNumber,
      photo: result.user.photoURL,
      source: "Portfolio",
      googleCreatedAt: result.user.metadata.createdAt,
      createdAt: new Date().getTime()
    };
    const db = getFirestore(firebaseApp);
    const q = query(collection(db, "Users"), where("email", "==", _user.email));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      user = doc.data();
      user.id = doc.id;
    });
    if (user === null) await addDoc(collection(db, "Users"), _user);
    return user;
  },
};

export default FaceRecognitionService;
