import { combineReducers } from "redux";
import facerecognition from "./facerecognition.reducer";
import intro from "./intro.reducers";
import projects from "./projects.reducers";
import technologies from "./technologies.reducer";

export default combineReducers({
  facerecognition,
  intro,
  projects,
  technologies,
});
