import { FaceRecognitionConstants } from "../constants";
import { FaceRecognitionService } from "../services";

const FaceRecognitionActions = {
  initialize: () => {
    const request = () => ({
      type: FaceRecognitionConstants.INITIALIZE_APP_REQUEST,
    });
    const success = (firebaseApp) => ({
      type: FaceRecognitionConstants.INITIALIZE_APP_SUCCESS,
      firebaseApp,
    });
    const failure = () => ({
      type: FaceRecognitionConstants.INITIALIZE_APP_FAILURE,
    });

    return async (dispatch) => {
      dispatch(request());
      const firebaseApp = await FaceRecognitionService.initialize();
      if (firebaseApp) {
        dispatch(success(firebaseApp));
      } else {
        dispatch(failure());
      }
    };
  },
};

export default FaceRecognitionActions;
