import { useEffect, useRef } from "react";

function VideoCall(props) {
  const localVideoRef = useRef();
  const localCanvasRef = useRef();
  const remoteVideoRef = useRef();
  const remoteCanvasRef = useRef();
  const remoteHeight = window.innerHeight * 0.86;
  const remoteWidth =
    window.innerWidth > 800 ? window.innerWidth * 0.5 : window.innerWidth;
  const localHeight = window.innerHeight * 0.86;
  const localWidth =
    window.innerWidth > 800 ? window.innerWidth * 0.5 : window.innerWidth;

  const initializeRenderers = async () => {
    var localVideo = localVideoRef.current;
    var remoteVideo = remoteVideoRef.current;

    // Local
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: true,
    });
    localVideo.srcObject = stream;
    localVideo.play();
    var localCanvas = localCanvasRef.current;
    var localCtx = localCanvas.getContext("2d");
    localVideo.addEventListener("loadeddata", async () => {
      setInterval(() => {
        localCtx.drawImage(localVideo, 0, 0, localWidth, localHeight);
        localCtx.stroke();
      }, 100);
    });
    return stream;
  };

  useEffect(() => {
    var stream;
    (async () => {
      stream = await initializeRenderers();
    })();
    return () => {
      stream.getTracks().forEach((track) => track.stop());
    }
  }, []);

  return (
    <div>
      <video
        ref={localVideoRef}
        width={localWidth}
        height={localHeight}
        autoPlay
        preload="none"
        muted={true}
        playsInline={true}
        style={{ display: "none" }}
      />
      <video
        ref={remoteVideoRef}
        width={remoteWidth}
        height={remoteHeight}
        autoPlay
        preload="none"
        muted={true}
        playsInline={true}
        style={{ display: "none" }}
      />
      <canvas
        ref={localCanvasRef}
        width={localWidth}
        height={localHeight}
        style={{
          position: "absolute",
          height: "25%",
          width: "25%",
          bottom: 15,
          right: 15,
          borderRadius: "10px",
        }}
      />
      <canvas ref={remoteCanvasRef} width={remoteWidth} height={remoteHeight} />
    </div>
  );
}

export default VideoCall;
