const firebaseConfig = {
    apiKey: "AIzaSyCvNqBI2CS07qtN74zdZtSa-a7to2TUGz8",
    authDomain: "wrec-er.firebaseapp.com",
    projectId: "wrec-er",
    storageBucket: "wrec-er.appspot.com",
    messagingSenderId: "257757091675",
    appId: "1:257757091675:web:ded3af3a4a3c4c7b1d2c7e",
    measurementId: "G-25HXVT63W4"
};

export default firebaseConfig;