import React, { useEffect, useState, Suspense } from "react";
import { useDispatch } from "react-redux";
import { FaceRecognitionActions } from "./actions";
import "./App.scss";

import { Background } from "./components";
const Screen = React.lazy(() => import("./screens/Screen"));

function App() {
  const dispatch = useDispatch();

  const [mousePosition, setMousePosition] = useState({ x: null, y: null });
  const [touchPath, setTouchPath] = useState([]);
  const [gesture, setGesture] = useState("");

  function _onMouseMove(e) {
    setMousePosition({ x: e.screenX, y: e.screenY });
  }

  function handleTouchStart(e) {
    setTouchPath([]);
  }

  function handleTouchMove(e) {
    setTouchPath((_touchPath) => [
      ...touchPath,
      { x: e.targetTouches[0].clientX, y: e.targetTouches[0].clientY },
    ]);
  }

  function handleTouchEnd(e) {
    findGesture(touchPath);
  }

  function rightSwipe(touchPath) {
    // Right swipe for navbar
    if (
      touchPath.length &&
      touchPath[touchPath.length - 1].x - touchPath[0].x > 40
    ) {
      const yPath = touchPath[0].y;
      var flag = false;
      for (var touch = 0; touch < touchPath.length - 2; touch++) {
        if (Math.abs(touchPath[touch].y - yPath) > 30) {
          flag = true;
          break;
        } else {
          if (touchPath.length - 3 > touch) {
            if (touchPath[touch].x < touchPath[touch + 1].x) {
              continue;
            } else {
              flag = true;
              break;
            }
          }
        }
      }
      return !flag;
    }
    return false;
  }

  function leftSwipe(touchPath) {
    // Left swipe for navbar
    if (
      touchPath.length &&
      touchPath[touchPath.length - 1].x - touchPath[0].x > 40
    ) {
      const yPath = touchPath[0].y;
      var flag = false;
      for (var touch = 0; touch < touchPath.length - 2; touch++) {
        if (Math.abs(touchPath[touch].y - yPath) > 30) {
          flag = true;
          break;
        } else {
          if (touchPath.length - 3 > touch) {
            if (touchPath[touch].x > touchPath[touch + 1].x) {
              continue;
            } else {
              flag = true;
              break;
            }
          }
        }
      }
      return !flag;
    }
    return false;
  }

  function findGesture(touchPath) {
    if (rightSwipe(touchPath)) {
      setGesture("rightswipe");
    } else if (leftSwipe(touchPath)) {
      setGesture("leftswipe");
    } else setGesture("");
  }

  function logPageRoute() {
    // Removed for dev! Don't push to git
    window.gtag("send", "page_view", window.location.pathname);
  }

  useEffect(() => {
    logPageRoute();
    dispatch(FaceRecognitionActions.initialize());
  }, []);

  return (
    <div
      className="App"
      onMouseMove={_onMouseMove}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <Background />
      <Suspense
        fallback={
          <div className=".App-logo Screen">
            <h1 color="white">Loading</h1>
          </div>
        }
      >
        <Screen
          className="App"
          mousePosition={mousePosition}
          gesture={gesture}
        />
      </Suspense>
    </div>
  );
}

export default App;
