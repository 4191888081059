import { FaceRecognitionConstants } from "../constants";

const initialState = {};

const FaceRecognitionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FaceRecognitionConstants.INITIALIZE_APP_REQUEST:
      return { ...state };
    case FaceRecognitionConstants.INITIALIZE_APP_SUCCESS:
      return { ...state, firebaseApp: action.firebaseApp };
    case FaceRecognitionConstants.INITIALIZE_APP_FAILURE:
      return { ...state };
    default:
      return state;
  }
};

export default FaceRecognitionReducer;
