import { ProjectsConstants } from "../constants";

const initialState = {};

const ProjectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ProjectsConstants.GET_PROJECTS_REQUEST:
      return { ...state, projectsLoading: true };
    case ProjectsConstants.GET_PROJECTS_SUCCESS:
      try {
        const projectsList = [...new Set([...state.projects.projectsList, ...action.Projects])];
        const projects = { languageLogos: state.projects.languageLogos, projectsList: projectsList };
        return {
          ...state,
          projects: projects,
          projectsLoading: false
        };
      } catch (e) {
        // console.log(e);
        return { ...state, projects: action.Projects };
      }
    case ProjectsConstants.GET_PROJECTS_FAILURE:
      return { ...state, projectsLoading: false };
    case ProjectsConstants.GET_LANGUAGE_LOGOS_REQUEST:
      return { ...state, projects: { ...state?.projects }, languageLogosLoading: true };
    case ProjectsConstants.GET_LANGUAGE_LOGOS_SUCCESS:
      const projects = { projectsList: [], languageLogos: action.languageLogos };
      return { ...state, projects: projects, languageLogosLoading: false }
    case ProjectsConstants.GET_LANGUAGE_LOGOS_FALIURE:
      return { ...state, languageLogosLoading: false };
    default:
      return state;
  }
};

export default ProjectsReducer;
