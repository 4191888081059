import ApiService from "./api.service";

const BASE_URL = process.env.REACT_APP_PROJECTS_API_BASE_URL;
const ProjectsService = {
  getProjects: async (offset, languageLogos) => {
    const response = await ApiService.get(
      BASE_URL + `/?offset=${offset}`
    );
    if (response.status === 200 && response.data.success && response.data.__data.length) {
      let logoURL;
      response.data.__data.map((project) => {
        try {
          logoURL = languageLogos
            .split(`[${project.language}](`)[1]
            .split(")")[0];
          project.language = logoURL;
        } catch (e) {
          console.warn('Unable to find logo for project', project);
          project.language = null;
        } finally {
          return project;
        }
      });
      return response.data.__data;
    } else {
      return [];
    }
  },
  getLanguageLogos: async () => {
    const languageLogoResponse = await ApiService.get(
      "https://raw.githubusercontent.com/Ileriayo/markdown-badges/master/README.md",
    );
    return languageLogoResponse.data;
  }
};

export default ProjectsService;
