import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";

import { Link } from 'react-router-dom';

import './SideNavBar.scss';

function SideNavBar(props) {
	const [sideBarState, setSideBarState] = useState(false);
	const introStep = useSelector((state) => (!!state && state.intro.step)) || 0;

	useEffect(
		() => {
			if (introStep && introStep > 0 && introStep < 7) {
				setSideBarState(true);
			} else {
				if (props.gesture === '' && props.mousePosition.x) {
					if (props.mousePosition.x < 90) setSideBarState(true);
					else setSideBarState(false);
				} else {
					if (props.gesture === 'leftswipe') setSideBarState(false);
					if (props.gesture === 'rightswipe') setSideBarState(true);
				}
			}
		},
		[props.mousePosition.x, props.gesture, introStep]
	);


	return (
		<div className={sideBarState ? 'SideNavBar-Open' : 'SideNavBar-Close'}>
			<div className="SideNavBarIcons">
				<Link to="/" className="home-icon">
					<img src="/assets/home.webp" alt="Home" height="64" width="64" />
				</Link>
				<Link to="/technologies" className="technologies-icon">
					<img src="/assets/technologies.webp" alt="Technologies" height="64" width="64" />
				</Link>
				<Link to="/face-recognition" className="face-recognition-icon">
					<img src="/assets/face-recognition.webp" alt="Face Recognition" height="64" width="64" />
				</Link>
				<Link to="/timeline" className="timeline-icon">
					<img src="/assets/timeline.webp" alt="Timeline" height="64" width="64" />
				</Link>
				<Link to="/projects" className="projects-icon">
					<img src="/assets/projects.webp" alt="Projects" height="64" width="64" />
				</Link>
				<Link to="/settings" className="settings-icon">
					<img src="/assets/settings.webp" alt="Settings" height="64" width="64" />
				</Link>
			</div>
		</div>
	);
}

export default SideNavBar;
