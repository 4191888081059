import { useEffect, useState } from 'react';
import './ContentLoadingScreen.scss';

export default function ContentLoadingScreen(props) {

    const [progress, setProgress] = useState(0);

    useEffect(() => {
        setProgress(_progress => _progress + 10);
        return () => {
            setProgress(100);
        };
    }, [progress]);

    return (
        <div className="ContentLoadingScreen">
            <img className="mac" alt="mac" src="/assets/mac.webp" />
            <img className="loading-logo" alt="loading-logo" src="/assets/logo.webp" />
            <div className="loader">
                <div style={{ width: `${progress || 0}%` }} className="progress-bar"></div>
                <div className="loading-bar"></div>
            </div>
        </div>
    )
}