import { TechnologiesConstants } from '../constants';

const initialState = {};

const TechnologiesReducer = (state = initialState, action) => {
	switch (action.type) {
		case TechnologiesConstants.GET_TECHNOLOGIES_REQUEST:
			return { ...state };
		case TechnologiesConstants.GET_TECHNOLOGIES_SUCCESS:
			return { ...state, count: action.technologies.count, list: action.technologies.data };
		case TechnologiesConstants.GET_TECHNOLOGIES_FAILURE:
			return { ...state };

		default:
			return state;
	}
};

export default TechnologiesReducer;
