import ApiService from './api.service';

const BASE_URL = process.env.REACT_APP_TECHNOLOGIES_API_BASE_URL;
const TechnologiesService = {
	getTechnologies: async (search, options=null) => {
		const response = await ApiService.get(
			BASE_URL  + '/' + (search ? '?search=' + search : ''), options);
		if (response.status === 200 && response.data.success) {
			return response.data.__data;
		} else return null;
	}
};

export default TechnologiesService;
