import { IntroConstants } from '../constants';
import { SessionService } from '../services';

const IntroActions = {
    introStart: () => {
        const start = (introStatus) => ({ type: IntroConstants.INTRO_START, introStatus });

        return async (dispatch) => {
            const introStatus = SessionService.get('intro-status');
            if (introStatus)
                dispatch(start(introStatus))
            else
                dispatch(start(false));
        };
    },
    introChange: (step) => {
        const update = (step) => ({ type: IntroConstants.INTRO_UPDATE, step })

        return async (dispatch) => {
            dispatch(update(step));
        };
    },
    introStop: () => {
        const stop = () => ({ type: IntroConstants.INTRO_STOP });

        return async (dispatch) => {
            SessionService.set('intro-status', true);
            dispatch(stop());
        };
    },
    introRemove: () => {
        const remove = () => ({ type: IntroConstants.INTRO_SESSION_REMOVE });

        return async (dispatch) => {
            SessionService.remove('intro-status');
            dispatch(remove());
        };
    }
};

export default IntroActions;